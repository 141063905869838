const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME
const IS_STAGING = process.env.NEXT_PUBLIC_ENV === "staging"
const IS_PREPRODUCTION = process.env.NEXT_PUBLIC_ENV === "preproduction"
const IS_BUYER = APP_NAME === "buyer"
const IS_SELLER = APP_NAME === "seller"
const IS_INTERNAL = APP_NAME === "internal"
const IS_BLACKLIST = APP_NAME === "blacklist"
const IS_ACCOUNT = APP_NAME === "account"
const IS_VERIFICATOR = APP_NAME === "verificator"

// Note: remobe IS_[APP_NAME] after integrated to all apps
export const FLAG_DIET_COOKIE =
  (IS_STAGING || IS_PREPRODUCTION) &&
  (IS_BUYER ||
    IS_SELLER ||
    IS_INTERNAL ||
    IS_BLACKLIST ||
    IS_ACCOUNT ||
    IS_VERIFICATOR)
